var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page"},[(
      _vm.routeFrom &&
        (
          _vm.routeFrom.name === 'SphereDetail' ||
          _vm.routeFrom.name === 'GroupDetail' ||
          _vm.routeFrom.name === 'OrganisationDetail' ||
          _vm.routeFrom.name === 'Accounts'
        )
    )?_c('div',{staticClass:"back-link",on:{"click":function($event){return _vm.$router.push(_vm.routeFrom)}}},[_c('b-icon-chevron-left',{attrs:{"font-scale":"1.3"}}),(_vm.routeFrom.name === 'SphereDetail')?_c('span',[_vm._v(" "+_vm._s(_vm.capitalizeFirstLetter(_vm.$tc('words.organisationGroup')))+" ")]):(_vm.routeFrom.name === 'GroupDetail')?_c('span',[_vm._v(" "+_vm._s(_vm.capitalizeFirstLetter(_vm.$tc('words.userGroup')))+" ")]):_vm._e(),(_vm.routeFrom.name === 'OrganisationDetail')?_c('span',[_vm._v(" "+_vm._s(_vm.capitalizeFirstLetter(_vm.$tc('words.organisation')))+" ")]):_vm._e(),(_vm.routeFrom.name === 'Accounts')?_c('span',[_vm._v(" "+_vm._s(_vm.capitalizeFirstLetter(_vm.$tc('words.accountsManagement')))+" ")]):_vm._e()],1):_c('div',{staticClass:"back-link",on:{"click":function($event){return _vm.$router.push({ name: 'Users' })}}},[_c('b-icon-chevron-left',{attrs:{"font-scale":"1.3"}}),_c('span',[_vm._v(" "+_vm._s(_vm.$t('userDetail.usersList'))+" ")])],1),_c('b-container',{attrs:{"fluid":""}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var handleSubmit = ref.handleSubmit;
return [(_vm.loading)?_c('b-overlay',{attrs:{"id":"overlay-background","show":_vm.loading,"variant":'white',"opacity":0.7,"blur":'2px',"rounded":"sm","no-wrap":""}}):_c('b-row',[_c('b-col',{staticClass:"detail-form",staticStyle:{"overflow-x":"hidden"},attrs:{"cols":"8"}},[(_vm.currentUser)?_c('h2',[_vm._v(" "+_vm._s(_vm.currentUser.last_name)+" "+_vm._s(_vm.currentUser.first_name)+" ")]):_vm._e(),_c('UserForm',{attrs:{"user":_vm.currentUser,"roles":_vm.currentUserRoles,"loading":_vm.userDataLoading},on:{"update":_vm.updateUserData}})],1),_c('b-col',{staticClass:"detail-card",attrs:{"cols":"4"}},[_c('UserCard',{attrs:{"user":_vm.currentUser}}),_c('div',{staticClass:"side-footer"},[(!_vm.currentUser.is_superuser)?_c('b-button',{attrs:{"variant":"danger"},on:{"click":_vm.openDeleteModal}},[_vm._v(" "+_vm._s(_vm.$t('buttons.delete'))+" ")]):_vm._e(),_c('b-button',{staticStyle:{"float":"right"},attrs:{"variant":"outline-secondary"},on:{"click":function($event){return handleSubmit(_vm.saveUser)}}},[_vm._v(" "+_vm._s(_vm.$t('buttons.saveChanges'))+" ")]),_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":function($event){return _vm.$router.push({
                name: 'Logs',
                params: {
                  object: 'user',
                  id: _vm.currentUser.id,
                }
              })}}},[_c('b-icon',{attrs:{"icon":"clock-history","font-scale":"1.25"}}),_vm._v(" "+_vm._s(_vm.$t('words.activityFlow').toUpperCase())+" ")],1)],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }