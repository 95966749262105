<template>
  <div>
    <form>
      <!-- Utilisateur -->
      <h3 class="line-header">
        <img
          src="@/assets/icons/face_user.svg"
          alt="Icon face user"
        > {{ capitalizeFirstLetter($tc('words.user')) }}
      </h3>
      <b-overlay
        :show="loading"
        :variant="'white'"
        :opacity="0.7"
        :blur="'2px'"
      >
        <div class="form-row">
          <div class="form-group col-6">
            <label>
              {{ $t('forms.user.status') }}
            </label>
            <Multiselect
              v-model="formData.status"
              :options="userStatuses"
              track-by="choice"
              label="label"
              select-label=""
              selected-label=""
              deselect-label=""
              :searchable="false"
              :placeholder="$t('forms.user.status')"
              @select="changeStatus"
              @remove="removeStatus"
            />
          </div>
          <div class="form-group col-6 right">
            <!-- EMAIL_NOTIFICATIONS -->
            <div class="form-check">
              <input
                v-model="formData.emailNotifications"
                type="checkbox"
              >
              <label class="form-check-label">
                {{ $t('forms.user.emailNotifications') }}
              </label>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-8">
            <!-- ADDITIONNAL_ID -->
            <div
              v-if="isFieldVisible('additional_id', formConfig.hiddenFields)"
            >
              <label
                :class="isFieldRequired('additional_id', formConfig.requiredFields)"
              >
                {{ $t('forms.user.idComp.label') }}
              </label>
              <p class="label-help">
                {{ $t('forms.user.idComp.help') }}
              </p>
              <ValidationProvider
                v-slot="{ classes, errors }"
                ref="additional_id"
                :rules="isFieldRequired('additional_id', formConfig.requiredFields)"
              >
                <div
                  :class="classes"
                  class="control"
                >
                  <input
                    v-model="formData.idComp"
                    v-sanitize
                    class="form-control"
                    type="text"
                    :placeholder="$t('forms.user.idComp.label')"
                  >
                </div>
                <span class="form-errors">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
        </div>
        <div class="form-row">
          <!-- LAST_NAME -->
          <div
            v-if="isFieldVisible('last_name', formConfig.hiddenFields)"
            class="form-group col-6"
          >
            <label
              :class="isFieldRequired('last_name', formConfig.requiredFields)"
            >
              {{ $t('forms.user.lastname') }}
            </label>
            <ValidationProvider
              v-slot="{ classes, errors }"
              ref="last_name"
              :rules="isFieldRequired('last_name', formConfig.requiredFields)"
            >
              <div
                class="control"
                :class="classes"
              >
                <input
                  v-model="formData.lastName"
                  v-sanitize
                  class="form-control"
                  type="text"
                  :placeholder="$t('forms.user.lastname')"
                >
                <span class="form-errors">{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </div>
          <!-- FIRST_NAME -->
          <div
            v-if="isFieldVisible('first_name', formConfig.hiddenFields)"
            class="form-group col-6"
          >
            <label
              :class="isFieldRequired('first_name', formConfig.requiredFields)"
            >
              {{ $t('forms.user.firstname') }}
            </label>
            <ValidationProvider
              v-slot="{ classes, errors }"
              ref="first_name"
              :rules="isFieldRequired('first_name', formConfig.requiredFields)"
            >
              <div
                class="control"
                :class="classes"
              >
                <input
                  v-model="formData.firstName"
                  v-sanitize
                  class="form-control"
                  type="text"
                  :placeholder="$t('forms.user.firstname')"
                >
                <span class="form-errors">{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </div>
        </div>
        <!-- COMMENTS -->
        <div class="form-row">
          <div class="form-group col-12">
            <label>
              {{ $t('forms.user.reason') }}
            </label>
            <textarea
              v-model="formData.comments"
              v-sanitize
              class="form-control"
              rows="1"
            />
          </div>
        </div>
        <div class="form-row">
          <!-- EMAIL -->
          <div
            v-if="isFieldVisible('email', formConfig.hiddenFields)"
            class="form-group col-6"
          >
            <label
              :class="isFieldRequired('email', formConfig.requiredFields)"
            >
              {{ $t('forms.user.email') }}
            </label>
            <ValidationProvider
              v-slot="{ classes, errors }"
              ref="email"
              :rules="`${isFieldRequired('email', formConfig.requiredFields)}|email`"
            >
              <div
                class="control"
                :class="classes"
              >
                <input
                  v-model="formData.email"
                  v-sanitize
                  class="form-control"
                  type="email"
                  :placeholder="$t('forms.user.email')"
                >
                <span class="form-errors">{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </div>
          <!-- PHONE -->
          <div
            v-if="isFieldVisible('phone', formConfig.hiddenFields)"
            class="form-group col-6"
          >
            <label
              :class="isFieldRequired('phone', formConfig.requiredFields)"
            >
              {{ $t('forms.user.phone') }}
            </label>
            <ValidationProvider
              v-slot="{ classes, errors }"
              ref="phone"
              :rules="{ regex: /(?:(?:\+|00)[1-9]{2,3}|0)\s*[1-9](?:[\s.-]*\d{2}){4}/ }"
            >
              <div
                class="control"
                :class="classes"
              >
                <input
                  v-model="formData.tel"
                  v-sanitize
                  class="form-control"
                  type="text"
                  :placeholder="$t('forms.user.phone')"
                >
                <span class="form-errors">{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </div>
        </div>
        <span
          v-if="userError"
          class="form-errors"
        >
          {{ userError }}
        </span>
        <!-- <div class="form-footer">
          <b-button
            variant="danger"
            @click="deleteUser"
          >
            {{ $t('buttons.delete') }}
          </b-button>
          <div>
            <b-button
              variant="outline-secondary"
              @click="$router.go(-1)"
            >
              {{ $t('buttons.cancel') }}
            </b-button>
            <b-button
              variant="primary"
              @click="handleSubmit(updateUser)"
            >
              {{ $t('buttons.saveChanges') }}
            </b-button>
          </div>
        </div> -->
      </b-overlay>

      <!-- Organisation -->
      <h3 class="line-header">
        <img
          src="@/assets/icons/company.svg"
          alt="Icon company"
        > {{ capitalizeFirstLetter($tc('words.organisation')) }}
      </h3>
      <UserOrgForm
        v-for="organisation of organisations"
        :key="`org-${organisation.position}`"
        :usergroup="organisation"
        :user-id="user.id"
        :mode="'edit'"
        @allowAdd="setCanAddOrganisation"
        @change="updateOrganisation"
        @delete="removeOrganisation"
      />
      <button
        class="add-button"
        :class="{
          disabled: !canAddOrganisation
        }"
        @click.prevent="addOrganisation"
      >
        <b-icon-plus />
        {{ $t('forms.user.addOrganisation') }}
      </button>

      <!-- Groupe d'utilisateurs -->
      <h3 class="line-header">
        <img
          src="@/assets/icons/user-group.svg"
          alt="Icon company"
        > {{ capitalizeFirstLetter($tc('words.userGroup')) }}
      </h3>
      <UserGroupForm
        v-for="group of groups"
        :key="`group-${group.position}`"
        :usergroup="group"
        :current-usergroups="groups"
        :user-id="user.id"
        :mode="'edit'"
        @allowAdd="setCanAddGroup"
        @change="updateGroup"
        @delete="removeGroup"
      />
      <button
        class="add-button"
        :class="{
          disabled: !canAddGroup
        }"
        @click.prevent="addGroup"
      >
        <b-icon-plus />
        {{ $t('forms.user.addUserGroup') }}
      </button>
    </form>
  </div>
</template>

<script>
import UserOrgForm from '@/components/Forms/UserOrgForm.vue';
import UserGroupForm from '@/components/Forms/UserGroupForm.vue';
import Multiselect from 'vue-multiselect';

import { mapState, mapActions } from 'vuex';

// import Swal from "sweetalert2";
import 'sweetalert2/dist/sweetalert2.min.css';

import { ValidationProvider, extend, configure } from 'vee-validate';
import { required, regex, email } from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: 'Ce champ est requis'
});
extend('regex', {
  ...regex,
  message: 'Veuillez entrer un numéro de téléphone valide'
});
extend('email', {
  ...email,
  message: 'Veuillez entrer une adresse e-mail valide'
});
configure({
  classes: {
    valid: 'is-valid',
    invalid: 'is-invalid'
  }
});

const LABEL_ID_COMP = process.env.VUE_APP_LABEL_ID_COMP;

export default {
  name: 'UserForm',

  components: {
    UserOrgForm,
    UserGroupForm,
    Multiselect,
    ValidationProvider
  },

  props: {
    user: {
      type: Object,
      default: () => { return {}; }
    },

    roles: {
      type: Array,
      default: () => { return []; }
    },

    loading: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      formData: {
        status: {
          choice: null,
          label: null
        },
        emailNotifications: false,
        idComp: null,
        lastName: null,
        firstName: null,
        comments: null,
        email: null,
        tel: null
      },
      organisations: [],
      groups: [],
      canAddOrganisation: true,
      canAddGroup: true,
      labelIdComp: LABEL_ID_COMP
    };
  },

  computed: {
    ...mapState('users', [
      'userError',
      'currentUserRoles',
      'userStatuses'
    ]),

    formConfig() {
      return this.$config.forms.user;
    },
  },

  watch: {
    formData: {
      deep: true,
      handler(newValue) {
        if (newValue && newValue.status) {
          this.$emit('update',
            {
              ...this.user,
              additional_id: newValue.idComp,
              email_notification: newValue.emailNotifications,
              first_name: newValue.firstName,
              last_name: newValue.lastName,
              comments: newValue.comments,
              email: newValue.email,
              phone_number: newValue.tel,
              status: newValue.status.choice,
            }
          );
        }
      }
    },

    roles: {
      deep: true,
      handler(newValue) {
        this.organisations = [];
        let orgPosition = 1;
        for (
          const organisation of newValue
            .filter(el => el.usergroup.usergroup_type.codename === 'organisation')
            .sort((a, b) => {
              return a.usergroup.display_name.localeCompare(b.usergroup.display_name);
            })
        ) {
          organisation.position = orgPosition;
          organisation.roleStatus = organisation.status;
          this.organisations.push(organisation);
          orgPosition += 1;
        }

        this.groups = [];
        let groupPosition = 1;
        for (
          const group of newValue
            .filter(el => el.usergroup.usergroup_type.codename === 'user-group')
            .sort((a, b) => {
              return a.usergroup.display_name.localeCompare(b.usergroup.display_name);
            })
        ) {
          group.position = groupPosition;
          group.roleStatus = group.status;
          this.groups.push(group);
          groupPosition += 1;
        }
      }
    }
  },

  created() {
    this.formData.status.choice = this.user.status;
    this.formData.emailNotifications = this.user.email_notification;
    this.formData.status.label = this.userStatuses.find(el => el.choice === this.user.status).label;
    this.formData.idComp = this.user.additional_id;
    this.formData.lastName = this.user.last_name;
    this.formData.firstName = this.user.first_name;
    this.formData.comments = this.user.comments;
    this.formData.email = this.user.email;
    this.formData.tel = this.user.phone_number;

    let orgPosition = 1;
    for (
      const organisation of this.roles
        .filter(el => el.usergroup.usergroup_type.codename === 'organisation')
        .sort((a, b) => {
          return a.usergroup.display_name.localeCompare(b.usergroup.display_name);
        })
    ) {
      organisation.position = orgPosition;
      organisation.roleStatus = organisation.status;
      this.organisations.push(organisation);
      orgPosition += 1;
    }

    let groupPosition = 1;
    for (
      const group of this.roles
        .filter(el => el.usergroup.usergroup_type.codename === 'user-group')
        .sort((a, b) => {
          return a.usergroup.display_name.localeCompare(b.usergroup.display_name);
        })
    ) {
      group.position = groupPosition;
      group.roleStatus = group.status;
      this.groups.push(group);
      groupPosition += 1;
    }
  },

  methods: {
    ...mapActions('users', [
      'GET_USER_DETAIL',
      'PATCH_USER',
      'REMOVE_USER_ROLE'
    ]),

    async changeStatus(e) {
      const data = { status: e.choice };
      this.busy = true;
      await this.PATCH_USER({ id: this.user.id, data: data });
      await this.GET_USER_DETAIL(this.user.id);
      this.busy = false;
    },

    /* prevent removing status */
    removeStatus(e) {
      this.formData.status = e;
    },

    setCanAddOrganisation(e) {
      this.canAddOrganisation = e;
    },

    addOrganisation() {
      const position = this.organisations.length + 1;
      this.organisations.push({
        position: position,
        role: null,
        roleStatus: null,
        usergroup: {}
      });
      this.canAddOrganisation = false;
    },

    updateOrganisation(e) {
      const index = this.organisations.findIndex(el => el.position === e.position);
      this.organisations[index] = {
        position: e.position,
        role: e.data.role,
        roleStatus: e.data.roleStatus,
        usergroup: e.data.organisation
      };
    },

    removeOrganisation(e) {
      const index = e - 1;
      this.organisations.splice(index, 1);
      this.canAddOrganisation = true;
    },

    setCanAddGroup(e) {
      this.canAddGroup = e;
    },

    addGroup() {
      const position = this.groups.length + 1;
      this.groups.push({
        position: position,
        role: null,
        roleStatus: null,
        usergroup: {}
      });
      this.canAddGroup = false;
    },

    updateGroup(e) {
      const index = this.groups.findIndex(el => el.position === e.position);
      this.groups[index] = {
        position: e.position,
        role: e.data.role,
        roleStatus: e.data.roleStatus,
        usergroup: e.data.group
      };
    },

    removeGroup(e) {
      if (e) {
        const index = e - 1;
        this.groups.splice(index, 1);
      } else {
        this.groups.pop();
      }
      this.canAddGroup = true;
    },

    updateUser() {
      const updatedUser = {
        ...this.user,
        additional_id: this.formData.idComp,
        email_notification: this.formData.emailNotifications,
        first_name: this.formData.firstName,
        last_name: this.formData.lastName,
        comments: this.formData.comments,
        email: this.formData.email,
        phone_number: this.formData.tel,
        status: this.formData.status.choice,
      };
      this.$emit('save', updatedUser);
    }
  }
};
</script>
