<template>
  <div class="page">
    <div
      v-if="
        routeFrom &&
          (
            routeFrom.name === 'SphereDetail' ||
            routeFrom.name === 'GroupDetail' ||
            routeFrom.name === 'OrganisationDetail' ||
            routeFrom.name === 'Accounts'
          )
      "
      class="back-link"
      @click="$router.push(routeFrom)"
    >
      <b-icon-chevron-left font-scale="1.3" />
      <span v-if="routeFrom.name === 'SphereDetail'">
        {{ capitalizeFirstLetter($tc('words.organisationGroup')) }}
      </span>
      <span v-else-if="routeFrom.name === 'GroupDetail'">
        {{ capitalizeFirstLetter($tc('words.userGroup')) }}
      </span>
      <span v-if="routeFrom.name === 'OrganisationDetail'">
        {{ capitalizeFirstLetter($tc('words.organisation')) }}
      </span>
      <span v-if="routeFrom.name === 'Accounts'">
        {{ capitalizeFirstLetter($tc('words.accountsManagement')) }}
      </span>
    </div>
    <div
      v-else
      class="back-link"
      @click="$router.push({ name: 'Users' })"
    >
      <b-icon-chevron-left font-scale="1.3" />
      <span>
        {{ $t('userDetail.usersList') }}
      </span>
    </div>
    <b-container fluid>
      <ValidationObserver v-slot="{ handleSubmit }">
        <b-overlay
          v-if="loading"
          id="overlay-background"
          :show="loading"
          :variant="'white'"
          :opacity="0.7"
          :blur="'2px'"
          rounded="sm"
          no-wrap
        />
        <b-row v-else>
          <b-col
            cols="8"
            class="detail-form"
            style="overflow-x: hidden;"
          >
            <h2 v-if="currentUser">
              {{ currentUser.last_name }} {{ currentUser.first_name }}
            </h2>
            <UserForm
              :user="currentUser"
              :roles="currentUserRoles"
              :loading="userDataLoading"
              @update="updateUserData"
            />
          </b-col>
          <b-col
            cols="4"
            class="detail-card"
          >
            <UserCard
              :user="currentUser"
            />
            <div class="side-footer">
              <b-button
                v-if="!currentUser.is_superuser"
                variant="danger"
                @click="openDeleteModal"
              >
                {{ $t('buttons.delete') }}
              </b-button>
              <b-button
                variant="outline-secondary"
                style="float: right"
                @click="handleSubmit(saveUser)"
              >
                {{ $t('buttons.saveChanges') }}
              </b-button>
              <b-button
                variant="outline-secondary"
                @click="$router.push({
                  name: 'Logs',
                  params: {
                    object: 'user',
                    id: currentUser.id,
                  }
                })"
              >
                <b-icon
                  icon="clock-history"
                  font-scale="1.25"
                />
                {{ $t('words.activityFlow').toUpperCase() }}
              </b-button>
            </div>
          </b-col>
        </b-row>
      </ValidationObserver>
    </b-container>
  </div>
</template>

<script>
import UserForm from '@/components/Forms/UserForm.vue';
import UserCard from '@/components/Cards/UserCard.vue';

import { ValidationObserver } from 'vee-validate';


import { mapState, mapMutations, mapActions } from 'vuex';

export default {
  name: 'UserDetail',

  components: {
    UserForm,
    UserCard,
    ValidationObserver
  },

  data() {
    return {
      loading: true,
      userDataLoading: false,
      routeFrom: null,
      userData: null
    };
  },

  computed: {
    ...mapState('users', [
      'currentUser',
      'currentUserRoles'
    ])
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.routeFrom = from;
    });
  },

  created() {
    this.loading = true;
    this.GET_USER_DETAIL(this.$route.params.id)
      .then(() => {
        this.userData = this.currentUser;
        this.loading = false;
      });
  },

  mounted() {
    // Watch modal closing event
    this.$root.$on('bv::modal::hide', () => {
      this.deletingUser = false;
    });
  },

  methods: {
    ...mapMutations('modal', [
      'OPEN_MODAL',
    ]),
    ...mapActions('users', [
      'GET_USER_DETAIL',
      'GET_USERS_LIST',
      'UPDATE_USER',
      'DELETE_USER'
    ]),
    ...mapActions('organisations', [
      'GET_ORGANISATIONS_LIST'
    ]),

    updateUserData(data) {
      this.userData = data;
    },

    saveUser() {
      this.userDataLoading = true;
      this.UPDATE_USER({
        id: this.currentUser.id,
        data: this.userData
      }).then(() => {
        Promise.all([
          this.GET_USER_DETAIL(this.$route.params.id),
          this.GET_USERS_LIST({
            direction: null,
            field: null
          }),
          this.GET_ORGANISATIONS_LIST({
            direction: null,
            field: null
          })
        ]).then(() => {
          this.userDataLoading = false;
        });
      });
    },

    openDeleteModal() {
      this.OPEN_MODAL({
        modal: 'confirmation',
        open: true,
        title: this.$t('modals.deleteUser.title'),
        content: this.$t('modals.deleteUser.content', {
          user: `${this.currentUser.first_name} ${this.currentUser.last_name}`
        }),
        trigger: this.deleteUser
      });
    },
    deleteUser() {
      this.loading = true;
      this.DELETE_USER(this.currentUser)
        .then(() => {
          this.$router.push({ name: 'Users' });
        })
        .catch(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="less" scoped>

.container-fluid {
  margin: 1em;
  width: calc(100% - 2em);
}

.detail-form {
  padding-bottom: 200px;
  margin-bottom: - 200px;
}
</style>
